// export const serverHost = "https://api.nethunt.candidate.psglogin.in/api"
// export const server = "https://api.nethunt.candidate.psglogin.in"

// export const serverHost = "http://127.0.0.1:8000/api"
// export const server = "http://127.0.0.1:8000"

export const serverHost = "https://nethunt.alumni.psglogin.in/api"
export const server = "https://nethunt.alumni.psglogin.in"

// export const serverHost = "https://api.nethunt.alumni.psglogin.in/api"
// export const server = "https://api.nethunt.alumni.psglogin.in"